$(document).ready(function () {
    var currentPopup = null;
    $("body").on('click', "[data-popup]", function (ev) {
        var id = $(this).data("popup");
        currentPopup = id;
        $(".popup").css("display", "block");
        setTimeout(function () {
            $(".popup").addClass("active").addClass(id).addClass($currentSlide.data("slide"));
            if ($currentSlide.hasClass("static")) {
                $(".popup").addClass("static");
            }
        }, 0);
        $.ajax({
            url: ajaxurl,
            data: {
                action: 'popup_content',
                popup_id: id,
                lang: lang,
                object_id: $(this).data("popup-object-id")
            },
            type: 'GET'
        }).done(function (response) {
            console.log(response);

            $("html,body").scrollTop(0);
            $(".popup_content")
                .html(response);
            $(".popup_content_wrap")
                .css("opacity", 1);
            $(".popup_loader_wrap").css("opacity", 0);

            $("body").addClass("popup_active");
        });
    });
    function closePopup(){
        if (1 || currentPopup) {
            setTimeout(function () {

                $(".popup").removeClass(currentPopup).removeClass("static").removeClass($currentSlide.data("slide"));
                currentPopup = null;
            }, 900);
        }
        $("body").removeClass("popup_active");
        $(".popup").removeClass("active");
        setTimeout(function () {
            $(".popup_content")
                .html("");
            $(".popup_content_wrap")
                .css("opacity", 0);
            $(".popup_loader_wrap").css("opacity", 1);
            $(".popup").css("display", "none");
        }, 500);
    }
    $(document).keyup(function(e) {
        if (e.keyCode === 27){
            closePopup();
        }
    });
    $(".popup_close").click(function () {
        closePopup();
    })
});