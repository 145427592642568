(function ($) {
    function getScrollBarWidth () {
        var inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "200px";

        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "200px";
        outer.style.height = "150px";
        outer.style.overflow = "hidden";
        outer.appendChild (inner);

        document.body.appendChild (outer);
        var w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
        if (w1 == w2) w2 = outer.clientWidth;

        document.body.removeChild (outer);

        return (w1 - w2);
    };
    function _hide_loader(){

        $("#static_loader").velocity('transition.fadeOut',{
            display: 'none',
            duration: 500,
            delay:200,
        })
    }
    function _ajax_content(id,$slide,callback){
        $.ajax({
            url: ajaxurl,
            data:{
                action:'static_content',
                lang:lang,
                object_id: slides[id]['object_id']
            },
            type: 'GET'
        }).done(function(response) {
            console.log(response);
            callback(response);
        });
    }
    function _show_static(id, $slide,offset){
        $slide[0].style.display = 'none';
        $slide[0].offsetHeight;
        $slide[0].style.display = 'flex';
        console.log("OFF: ",offset);
        setTimeout(function(){
            //return this.get(0).scrollHeight > this.height();

            $slide.children().velocity("transition.fadeIn", {
                delay: 0,
                duration:800,
                stagger: 250,
                display:null
            });
        },500);
    }
    $("body").on("click",".open-gallery",function(){
        var items =  JSON.parse($('#'+$(this).data("gallery")+'_items').html());
        var pswp = $(".pswp")[0];
        var options = {
            history: false
        };
        var gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, items, options);
        gallery.init();
    });
    add_filter('transition',function(transition,id,old_id,$slide,$oldSlide){
    });
    add_filter('open-slide',function(offset,id,transition,$slide){
        if (slides[id]['slide_type'] == 'static') {
            $("body").addClass("static-slide-type");
            $slide.addClass("active");
            $(".top_nav").addClass("static");
            if($slide.data("loaded")){
                _hide_loader();
                _show_static(id, $slide,offset);
            }else{
                if($currentSlide == null){
                    $("#static_loader").velocity('transition.fadeIn', {
                        display: 'flex',
                        duration: 200,
                    });
                }
                _ajax_content(id,$slide,function(response){
                    _hide_loader();
                    $slide[0].innerHTML+=(response);
                    $slide.children().css("opacity",0);
                    $slide.find(".static_loaded").css("opacity",1);
                    $slide.data("loaded",true);
                    _show_static(id,$slide);
                });
            }
        }
        return offset;
    });
    add_filter('close-slide',function(offset,transition,old_id,$slide,$new_slide){
        if (slides[old_id]['slide_type'] == 'static') {
            var $el = $slide.children();//.find('.row');
            var duration = 400;
            $el.velocity("transition.fadeOut", {
                delay: 0,
                duration:duration,
                stagger: 100,
                display:null
            });
            offset += duration;/// $el.length*duration+150*$el.length;
            if (transition == 'static-static') {
                if (1 || $new_slide.data("loaded") != true) {
                    $("#static_loader").velocity('transition.fadeIn', {
                        display: 'flex',
                        duration: 200,
                    });
                }
            }else{
            }
        }else{
        }
        if(slides[$new_slide.data("slide")]['slide_type'] != 'static'){
            $(".top_nav").removeClass("static");
            $("body").removeClass("static-slide-type");
        }
        return offset;
    });
})(jQuery);

function onDistributorsChange(){
    var val = $("#distributor_select").val();
    $(".distributor_list.active").removeClass("active");
    $("#distributors_"+val).addClass("active");
}