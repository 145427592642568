function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name,"",-1);
}

jQuery(document).ready(function(){
    $ = jQuery;
    //push_voda_history(init_slide);
    if(0&&is_mobile()){
        open_slide(init_slide);
    }else{
        preload('init',null,function(){
            open_slide(init_slide);
        });
    }
    
    //apply_filter('slide-'+init_slide+'_open');
    var init_size = get_screen_layout();
    $(window).resize(function(){
        //mobile/desktop differ not only in css, but also in resources loaded so we have to refresh, whenever screen size goes beyond breakpoint
        if(get_screen_layout() != init_size){
            location.reload();
        }
    });
    if(readCookie('voda_newsletter') == null){
        //exit intent
        ouibounce(document.getElementById('newsletter_wrap'),{
            aggressive: true,
            callback: function() {
                openNewsletter();
            }
        });
        //or just 15 seconds
        setTimeout(function(){
            openNewsletter();
        },1000*15);
    }
    function openNewsletter(){
        var $n = $("#newsletter_wrap");
        $n[0].style.display = "none";
        $n[0].offsetHeight;
        $n[0].style.display="block";
        setTimeout(function(){

            $("#newsletter").css("opacity",1);
        },100);
    }
    function closeNewsletter(){
        $("#newsletter").css("opacity",0);
        setTimeout(function(){
            $("#newsletter_wrap").remove();
        },500);
        createCookie('voda_newsletter','1',7);
    }
    $(".close_newsletter").click(function(){
        closeNewsletter();
    });
    $("#newsletter").click(function(ev){
        if($(ev.target).closest("#mc_embed_signup").length == 0){
            closeNewsletter();
        }
    });
});
function get_screen_layout(){
    return is_mobile()?'mobile':'desktop';
}
function is_mobile(){
    return window.matchMedia("(max-width: 768px)").matches;
}
function is_desktop(){
    return !is_mobile();
}