var ticksPerFrame = 0;
var lastMove = 0;
var tickCount = 0, frameIndex = 0;
var numberOfFrames = 31;
var continue_bottle_animation = false;
var $currentRender = null;
var frameWidth = 0;
var curAnimDiff = 0;
var animDuration = 2500;
var animationTimedout = true;
var lastRenderTime = 0;
var endAnimTime = 0;
var direction = 1;
var timedTime = 0;
var RENDER_MODE = "canvas";
var bottleMaxHeight = 0;
(function () {

    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame']
            || window[vendors[x] + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function (callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function () {
                    callback(currTime + timeToCall);
                },
                timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function (id) {
            clearTimeout(id);
        };
}());
$(document).ready(function () {
    if(is_mobile()){

        bottleMaxHeight = window.innerHeight-200;
    }
    $(window).on('mousemove', function () {
        ticksPerFrame = 1;
        if (animationTimedout && continue_bottle_animation && Date.now() - timedTime > 0) {
            animationTimedout = false;
            lastMove = Date.now();//newLast;
            console.log("forcing anim");
            window.requestAnimationFrame(_render_bottle);
        }
    });
    $(window).resize(function () {
        recalculateMaxBottleHeight($currentSlide);
        $(".bottle,.bottle_wrap").css("max-height", "none");//bottleMaxHeight+"px");
        if ($currentRender) {
            _recalculate_animation_scale();
            _render_bottle_frame();
        } else if ($currentSlide) {
            render_static_bottle("", $currentSlide);
        }
    }).resize();
});

function recalculateMaxBottleHeight($slide){
    if (is_mobile()) {
        $(".gradient.active .lines").css("max-height",(250+bottleMaxHeight)+"px");
    }else{
        bottleMaxHeight = window.innerHeight - 150;
    }
}

function _render_bottle() {
    if (animationTimedout) {
        return false;
    }
    curAnimDiff = Date.now() - lastMove;
    if (1) {// curAnimDiff < animDuration) {
        window.requestAnimationFrame(_render_bottle);
    } else {
    }
    if ($currentRender) {
        //console.log("anim frame");
        _update_tick();
        _render_bottle_frame();
        lastRenderTime = new Date().getTime();
    }

}

function _calculate_tick() {
    function easeInOut(t) {
        if (t <= 1) {
            return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
        } else {
            return 1;
            t = 2 - t;
            return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
        }
    }

    easeInQuart = function (t) {
        return t * t * t * t
    };
    easeInOutQuint = function (t) {
        return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
    }
    easeInOutCubic = function (t) {
        return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    }
    var dur = animDuration;
    var diff = new Date().getTime() - lastRenderTime;// curAnimDiff;
    var x = 0;//dur / 1.5;
    x = (dur - diff);
    //console.log("time: ", curAnimDiff/animDuration);
    return easeInOut(curAnimDiff / animDuration);
}

function _update_tick() {

    var percent = _calculate_tick();
    frameIndex = Math.floor((numberOfFrames - 1) * percent);
    if (direction == -1) {
        frameIndex = (numberOfFrames - 1) - frameIndex;
    }
    //console.log(frameIndex,numberOfFrames);
    if ((frameIndex <= 0 && direction == -1) || ((frameIndex >= numberOfFrames - 1) && direction == 1)) {
        direction = -direction;
        animationTimedout = true;
        timedTime = Date.now();
    }
    //console.log(frameIndex);
}
function _render_bottle_frame() {
    if (RENDER_MODE == "canvas") {
        animData.context.clearRect(0, 0, animData.canvas.width, animData.canvas.height);

        // Draw the animation
        animData.context.drawImage(
            animData.image,
            frameIndex * frameWidth,
            0,
            frameWidth,
            animData.totalHeight,
            0,
            0,
            animData.canvas.width,
            animData.canvas.height);
    } else {
        $currentRender.css("background-position", -frameIndex * frameWidth * animData.scale + "px");
    }
}
function render_static_bottle(id, $slide, disable_scaling) {
    _recalculate_animation_scale($slide);
    if (RENDER_MODE == "canvas") {
        var el = $slide.find("canvas")[0];
        if (typeof el == "undefined") {
            return;
        }
        var ctx = el.getContext("2d");
        var img = new Image();
        img.src = $slide.find(".bottle").attr("src");
        ctx.clearRect(0, 0, el.width, el.height);
        // Draw the animation
        ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            0,
            0,
            el.width,
            el.height);
    }
}
function _recalculate_animation_scale($force_slide) {
    animData.scale = bottleMaxHeight / animData.totalHeight;
    if (animData.scale > 1) {
        animData.scale = 1;
    }
    var $slide = null;
    if (typeof $force_slide != "undefined") {
        $slide = $force_slide;
    } else if ($currentSlide) {
        $slide = $currentSlide;
    }
    if ($slide) {
        console.log($slide.data("slide"));
        if (typeof(slides[$slide.data("slide")]['bottle']) != "undefined" && typeof(slides[$slide.data("slide")]['bottle']['fake-width']) != "undefined") {
            var id = $slide.data("slide");
            var fakeW = slides[id]['bottle']['fake-width'];
            var fakeH = slides[id]['bottle']['fake-height'];
            var h = Math.min(bottleMaxHeight, fakeH);
            var realH = $slide.find(".bottle").data("real-height");
            var realW = $slide.find(".bottle").data("real-width");
            var ratio = h / fakeH;
            $slide.find(".bottle_wrap").css("height", h + "px");
            $slide.find(".bottle").css("height", ratio * realH + "px");
            console.log($slide.find(".bottle").data("real-width"));
            var el = $slide.find("canvas")[0];
            if (el) {
                el.width = realW * ratio;
                el.height = realH * ratio;
                $(el).css("left", ($slide.find(".bottle_wrap").width() - el.width) / 2 + "px");
            }
        } else if ($slide.find(".bottle").length) {

            var el = $slide.find("canvas")[0];
            if (typeof el != "undefined") {

                var s = bottleMaxHeight / $slide.find(".bottle")[0].height;
                if (s > 1) {
                    s = 1;
                }
                el.width = $slide.find(".bottle")[0].width * s;
                el.height = $slide.find(".bottle")[0].height * s;
            }

        }
    }
    if ($currentRender) {
        if (RENDER_MODE == "canvas") {
            var el = $currentRender.find("canvas")[0];
            el.width = frameWidth * animData.scale;
            el.height = animData.totalHeight * animData.scale;
        } else {
            $currentRender
                .css("width", frameWidth * animData.scale)
                .css("height", animData.totalHeight * animData.scale)
                .css("background-position", "0 0")
                .css("background-size", animData.totalWidth * animData.scale + "px " + animData.totalHeight * animData.scale + "px")

        }
    }

}
function unload_bottle_animation() {
    continue_bottle_animation = false;
}
var animData = {};
function load_bottle_animation(src, $bottle, $slide) {
    if (typeof src == "undefined") {
        return;
    }

    var i = new Image();
    console.log("fetching " + src, $currentSlide, $slide);
    i.src = src;
    i.onload = (function ($slide) {
        return function () {
            console.log("loaded", $slide);
            if ($slide == $currentSlide) {
                continue_bottle_animation = true;
                //set bg
                frameWidth = i.width / numberOfFrames;
                animData.image = i;
                animData.totalWidth = i.width;
                animData.totalHeight = i.height;
                $currentRender = $slide.find(".bottle_wrap");
                if (RENDER_MODE == "background") {
                    $currentRender
                        .css("background-image", 'url(' + src + ')');
                    $slide.find(".bottle").css("visibility", "hidden");
                } else {
                    var $c = $slide.find("canvas");
                    animData.canvas = $c[0];
                    animData.context = $c[0].getContext("2d");
                    if ($c[0].width != $bottle.width() || $c[0].height != $bottle.height()) {

                        $c[0].width = $bottle.width();
                        $c[0].height = $bottle.height();
                    }
                }
                _recalculate_animation_scale();
                //$("body").addClass("bottle_animation_loaded");

                tickCount = 0;
                frameIndex = 0;
                continue_bottle_animation = true;
                curAnimDiff = 0;
                lastMove = Date.now();//newLast;
                direction = 1;
                animOffset = 0;
                startAnimTime = new Date().getTime();
                //animationTimedout = true;
                _render_bottle_frame();
                //_render_bottle();
            }
        }
    })($slide);
}