$(document).ready(function () {
    function get_next_id(prev_or_next,id){
        if(typeof id == "undefined"){
            id = $currentSlide.data("slide");
        }
        var $menu = $(".menu[data-menu='" + slides[id]['menu'] + "']");
        var next = $menu.find("[data-slide-open='"+id+"']");
        if (prev_or_next == "next") {
            next = next.next(".menu-item");
        } else {
            next = next.prev(".menu-item");
        }
        if (next.length == 0) {
            if (prev_or_next == "next") {
                next = $menu.find(".menu-item").first();
            } else {
                next = $menu.find(".menu-item").last();
            }
        }
        return next;
    }
    function onMobileNav(prev_or_next) {
        var id = get_next_id(prev_or_next);
        _navigate_to_id(id.data("slide-open"));
    }
    add_filter('open-slide', function(offset, id, transition, $slide){
        var next = get_next_id('next',id).data("slide-open");
        var prev = get_next_id('prev',id).data("slide-open");
        $(".mobile_voda_nav img").removeClass("active");
        if(prev != next){
            $(".mobile_voda_nav .voda_prev").find(".nav_voda_"+prev).addClass("active");
        }
        $(".mobile_voda_nav .voda_next").find(".nav_voda_"+next).addClass("active");
        console.log(id,prev,next);
        if(slides[id]['slide_type'] == 'static'){
            $(".mobile_static_nav .title").text($slide.data("title"));
        }
        return offset;
    });
    $(".voda_next").click(function () {
        onMobileNav("next");
    });
    $(".voda_prev").click(function () {
        onMobileNav("prev");
    });
    $("body").click(function(ev){
        if($(".mobile_static_nav").hasClass("active")){
            if($(ev.target).closest(".mobile_static_nav").length == 0){
                $(".mobile_static_nav").removeClass("active");
            }
        }
    });
    function toggle_static_nav(){
        $(".mobile_static_nav").toggleClass("active");
    }
    $(".mobile_static_nav .title").click(function(){
       toggle_static_nav();
    });
    $(".menu-toggle").click(function(){
        $(this).toggleClass('is-active');
        $("body").toggleClass("menu-active");
        if($(this).hasClass("is-active")){
            $("html,body").animate({scrollTop:0},200);
        }
    })
    if(is_mobile()){
        $(".mobile_voda_nav .voda_nav_arrow").css("top",window.innerHeight/2+"px");
        $(".bottle").css("height",window.innerHeight*2/3+"px");
    }
});