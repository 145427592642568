function triggerCssAnimationOnElement($e, css) {
    return $e.removeClass(css),
        $e.hide().show(0),
        $e.addClass(css)
}
$("body").on("scroll", ".slide", function () {
    $(".slide.active canvas").css("margin-top", $(".slide.active").scrollTop() + "px");
});
$(document).ready(function () {
    add_filter('close-slide', function (offset, transition, old_id, $slide, $new_slide) {
        if (slides[old_id]['slide_type'] == 'voda') {
            var new_id = $new_slide.data("slide");
            _animate_dom(old_id, $slide, "h1", "slide-out", 1500, offset);
            _animate_dom(old_id, $slide, ".slide_side_col", "slide-out", 500, offset);
            offset += _animate_dom(old_id, $slide, ".slide_sub_content", "slide-out", 500, offset);
            offset += _animate_gfx(old_id, $slide, offset, 'out');
            if (old_id == 'voda-collagen') {
                $(".voda-collagen-buteleczki").velocity("transition.fadeOut", {
                    delay: offset / 2,
                    display: null,
                    duration: 1000
                });
                offset += 1000;
            }
            if (old_id == 'voda-infused') {
                $(".voda-infused-buteleczki").velocity("transition.fadeOut", {
                    delay: offset / 2,
                    display: null,
                    duration: 1000
                });
                offset += 1000;
            }
            if (slides[old_id]['voda_type'] != slides[new_id]['voda_type']) {
                $("body").removeClass("voda-" + slides[old_id]['voda_type'] + '-type');
            }
        }
        return offset;
    });
    add_filter('open-slide', function (offset, id, transition, $slide) {
        $currentRender = null;
        if (slides[id]['slide_type'] == 'voda') {
            $("body").addClass("voda-" + slides[id]['voda_type'] + '-type');
            if (id == 'voda-collagen') {
                $(".voda-collagen-buteleczki").velocity("transition.fadeIn", {
                    delay: 0,
                    display: null,
                    duration: 2000
                });
            }
            if (id == 'voda-infused') {
                $(".voda-infused-buteleczki").velocity("transition.fadeIn", {
                    delay: 0,
                    display: null,
                    duration: 2000
                });
            }
            $slide.find(".tag,.voda_more,.voda_description,h1").css("opacity", 0);
            $slide.find(".slide_sub_content").css("display", "block").css("opacity", 0);
            $slide.find(".slide_side_col,.partners").css("display", "block").css("opacity", 0);

            $slide.addClass("active");

            render_static_bottle(id, $slide);
            offset += 0.3 * _animate_gfx(id, $slide, offset, 'in');
            $slide.find(".slide_sub_content").css("opacity", 1);
            offset += 500;
            var h1offset = offset;
            offset += 0.5 * _animate_dom(id, $slide, "h1", "slide-in", 1000, offset);
            offset += _animate_dom(id, $slide, ".voda_description", "slide-in", 700, offset * 0.8);
            var of1 = _animate_tags(id, $slide, offset);
            offset += _animate_dom(id, $slide, ".partners", "fadeIn", 1500, offset * 0.5 + of1);
            _animate_dom(id, $slide, ".slide_side_col", "slide-in", 1500, h1offset);
        }
        return offset;
    });

});
$(window).resize(function () {
    recalculateLines();
});
$(window).resize(function () {
    //refreshCanvas($currentSlide);
});
function _open_slide(id, transition) {
    var offset = 0;
    var $slide = $(".slide[data-slide='" + id + "']");
    //$slide.css("display","flex");
    open_sidebar(slides[id]['menu']);
    offset = apply_filters('open-slide', offset, id, transition, $slide);

    $currentSlide = $slide;
    isAnimating = false;
    highlight_menu(id);
    setTimeout(function () {
        $("body").attr("data-slide", id);
        recalculateLines();
    }, 0);
    if (slides[id]['bottle']) {
        setTimeout(function () {
            isAnimating = false;
            if(is_desktop()) {
                var $b = $currentSlide.find(".bottle");
                load_bottle_animation(slides[id]['bottle']['animation'], $b, $slide);
            }
        }, offset);
    } else {
        isAnimating = false;
    }
}
function _close_slide(transition, $new_slide, call_after) {
    var offset = 0;
    var $slide = $currentSlide;
    var old_id = $slide.data("slide");
    offset = apply_filters('close-slide', offset, transition, old_id, $slide, $new_slide);

    setTimeout(function () {
        $slide.removeClass("active");
        if (typeof call_after != "undefined") {
            call_after();
        }
    }, offset);
}
var isAnimating = false;
var $currentSlide = null;
function open_slide(id, dont_do_loader) {
    var current_id = 0;
    if ($currentSlide) {
        current_id = $currentSlide.data("slide");
    }
    var bid = id;
    if (id == 'first') {
        id = $($(".menu[data-menu='" + slides[current_id]['voda_type'] + "'] a")[0]).data("slide-open");
    }
    console.log("open_slide: ", bid, id, current_id);
    var $slide = $(".slide[data-slide='" + id + "']");
    var transition = slides[id]['slide_type'];
    if ($currentSlide && id == current_id) {
        return id;
    }
    var preload_id = '.slide.' + id;
    if (!is_preloaded(preload_id, $slide)) {
        preload(preload_id, $slide, function () {
            console.log("PRELOAD/OPEN_SLIDE:" + id);
            open_slide(id);
        });
        return id;
    }
    console.log("OPEN_SLIDE:" + id);
    isAnimating = true;
    if ($currentSlide != null) {
        transition = slides[current_id]['slide_type'] + '-' + transition;
        var old_id = $currentSlide.data("slide");
        $("body").removeClass("voda-" + slides[current_id]['slide_type'] + '-type');
        setTimeout(function () {

            $("body").attr("data-bg-color", id);
            $(".gradient[data-slide='" + old_id + "']").removeClass("active");//velocity({opacity: 0});
            //$(".gradient.active").removeClass("active");
            $(".gradient[data-slide='" + id + "']").addClass("active");//velocity({opacity: 1});
            apply_filters('transition', transition, id, old_id, $slide, $currentSlide);
            _show_lines($slide, id, 500);
        }, 1000);
    } else {
        if (typeof dont_do_loader == "undefined") {
            var $l = $("#loader");
            if ($l.length > 0) {
                setTimeout(function () {
                    $l.css("opacity", 0);
                    open_slide(id, true);
                    setTimeout(function () {
                        $l.remove();
                    }, 200);
                }, 500);
                $l.find(".img_wrap").css("opacity", 0);
                return;
            }
        }
        $("body").attr("data-bg-color", id);
        $(".gradient[data-slide='" + id + "']").addClass("active");//velocity({opacity: 1});
        _show_lines($slide, id, 200);
    }
    if (slides[id]['slide_type'] == 'static' || slides[id]['voda_type'] == 'natural') {
        $(".sidebar").addClass("show-fb-natural");
    } else {
        $(".sidebar").removeClass("show-fb-natural");

    }

    if ($currentSlide) {
        var old_id = current_id;
        if (slides[id]['menu'] == 'hide') {
            setTimeout(function () {
                open_sidebar(slides[id]['menu']);
            }, 500);
        }
        _close_slide(transition, $slide, function () {
            var offset = 0;
            $currentSlide.css("display", "none").removeClass("flex");
            offset = apply_filters('slide-' + old_id + '_close', offset);
            var $b = $currentSlide.find(".bottle_wrap");
            setTimeout(function () {
                if (is_desktop()) {
                    $b.removeClass(slides[id]['bottle']['slide-in'])
                        .removeClass(slides[id]['bottle']['slide-out']);
                } else {
                    $b.removeClass('slideFadeOut')
                        .removeClass('slideFadeIn');
                }
            }, 100);
            setTimeout(function () {
                //isAnimating = false;
                _open_slide(id, transition);
            }, offset);
        });
    } else {
        open_sidebar(slides[id]['menu']);
        _open_slide(id, transition);
    }
    return id;
}