var events = {
    filters: {},
    actions: {}
}
function add_filter(key,func){
    if(typeof events.filters[key] == "undefined"){
        events.filters[key] = [func];
        return;
    }
    events.filters[key].push(func);
}
function apply_filters(key, val){
    for(var i in events.filters[key]){
        if(!events.filters[key].hasOwnProperty(i)){
            continue;
        }
        val = events.filters[key][i].apply(this,[].slice.call(arguments,1));
    }
    return val;
}