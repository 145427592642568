var isPushing = false;
(function ($) {
    $(document).ready(function(){

        $("body").on('click',"[data-slide-open]",function(ev){
            ev.stopPropagation();
            ev.preventDefault();
            var id = $(this).data("slide-open");
            _navigate_to_id(id);
            //openVoda($(this).data("open"));
        });
    })
    // Bind to StateChange Event
    History.Adapter.bind(window,'statechange',function(){ // Note: We are using statechange instead of popstate
        var State = History.getState(); // Note: We are using History.getState() instead of event.state
        console.log(State);
        if(isPushing){
            return;
        }
        if(isAnimating){
            return false;
        }
        if($currentSlide == null || $currentSlide.data("slide") != State.data.slide) {
            open_slide(State.data.slide);
        }
    });
})(jQuery);
function _navigate_to_id(id){
    if(isAnimating){
        return false;
    }
    if($currentSlide == null || $currentSlide.data("slide") != id){
        isPushing = true;
        var opened_id = open_slide(id);
        $('.active[data-slide-open]').removeClass("active");
        $('[data-slide-open="'+opened_id+'"]').addClass("active");
        push_voda_history(opened_id);
    }
    if(is_mobile()){

        $(".menu-toggle").removeClass('is-active');
        $("body").removeClass("menu-active");
        $("html,body").animate({scrollTop:0},200);
        $(".mobile_static_nav").removeClass("active");
    }
}
function push_voda_history(id){

    var title = "";
    if(typeof slides[id]['page_title'] != "undefined"){
        title = slides[id]['page_title'] + ' - Voda Naturalna';
    }else{
        var $slide = $(".slide[data-slide='"+id+"']");
        if($slide.data("title")) {
            title = $(".slide[data-slide='" + id + "']").data("title") + ' - Voda Naturalna';
        }

    }
    History.pushState({'slide': id},title, $('[data-slide-open="'+id+'"]').attr("href"));
    isPushing = false;
}
var currentMenu = null;
function highlight_menu(id){
    $(".active[data-slide-open]").removeClass("active");
    $("[data-slide-open='"+id+"']").addClass("active");
}
function open_sidebar(id){
    if(is_mobile()){
        return;
    }
    var offset = 0;
    var $menu = $(".sidebar .menu[data-menu='"+id+"']");
    if(id == currentMenu){
        return;
    }
    if(id == 'hide'){
        if(currentMenu){
            currentMenu = null;
            $(".menu.active").css("opacity",0).css("display","none").find(".middle_animation").css("display","block").css("opacity",0);
            $(".home_nav").css("opacity",1);
            $(".sidebar").css("left",-200);
            $(".home,.social").velocity({opacity: 0},{duration: 1000,stagger: 300, delay: 1500});
        }
        return;
    }
    if(currentMenu){
        var $oldmenu = $(".sidebar .menu[data-menu='"+currentMenu+"']");
        var $els = $oldmenu.find(".middle_animation");
        $els.velocity("transition.slideLeftOut",{
            delay: 0,
            stagger: 100,
            drag: false//true
        });
        offset += $els.length*100;
        if($oldmenu.data("logo") != $menu.data("logo")){
            $(".sidebar .logo[data-logo='"+$oldmenu.data("logo")+"']").css("opacity",0);
        }
        setTimeout(function(){
            $oldmenu.css("display","none");
        },offset);

    }else{
        $(".home_nav").css("opacity",0);
        $(".sidebar").css("left",0);
        $(".home,.social").velocity({opacity: 1},{duration: 1000,stagger: 300, delay: 1500});
    }
    currentMenu = id;
    setTimeout(function(){
        $menu.addClass("active").css("opacity",1).css("display","block").find(".middle_animation").css("display","block").css("opacity",0);
        //$menu.css("visibility","visible");
        //$(".sidebar .logo[data-logo='"+$menu.data("logo")+"']").css("opacity",1);
        setTimeout(function(){
            $menu.find(".middle_animation").velocity("transition.slideRightIn",{
                delay: 500,
                stagger: 100,
                drag: false//true
            });
        },100);
    }, offset);
}
function close_sidebar(){

}