function _animate_dom(slide_id, $slide, selector, type, duration, delay) {
    var $el = $slide.find(selector);
    var attr = '';
    if (type != 'slide-in' && type != 'slide-out') {
        attr = type;//nazwa animacji a nie atrybut
    } else {
        if (typeof slides[slide_id][selector] == "undefined" || typeof slides[slide_id][selector][type] == "undefined") {
            //domyslna animacja
            if (type == 'slide-in') {
                attr = 'slideLeftIn';
            } else {
                attr = 'slideLeftOut';
            }
        } else {
            attr = slides[slide_id][selector][type];
        }
    }
    $el.velocity("transition." + attr, {
        duration: duration,
        delay: delay,
        easing: "easeInOutCubic",
        display: null
    });
    if ($el.length == 0) {
        return 0;
    }
    return duration;
}
function _animate_gfx(slide_id, $slide, offset, in_or_out) {
    var id = slide_id;
    offset = offset * 0.5;
    var count = 0;
    var animation = '';
    if (in_or_out == 'in') {
        $slide.find(".slide_gfx__fade").css("opacity", 1);
        setTimeout(function () {
            if (slides[id]['bottle']) {

                var $b = $slide.find(".bottle_wrap");
                if (1||is_desktop()) {
                    animation = slides[id]['bottle']['slide-in'];
                } else {
                    animation = 'slideFadeIn';
                }
                $b.addClass(animation);
            }
        }, offset);
        count = 1100;
    } else {
        $slide.find(".slide_gfx__fade").css("opacity", 0);
        setTimeout(function () {
            $(".gradient[data-slide='" + id + "']").find(".slide_gfx__fade").css("opacity", 0);
        }, offset);
        if (slides[id]['bottle']) {
            setTimeout(function () {
                var $b = $slide.find(".bottle_wrap");
                if (is_desktop()) {
                    animation = slides[id]['bottle']['slide-out'];
                } else {
                    animation = 'slideFadeOut';
                }
                $b.addClass(animation);
            }, offset);
            count = 1100;
        } else {
            //lekkie opoznienie, bo za szybko
            count = 600;
        }
    }
    return count;
}
function _animate_tags(slide_id, $slide, offset) {
    offset = offset * 0.5;
    var $tags = $slide.find(".tag");
    $tags.velocity("transition.slideLeftIn", {
        delay: offset + 500,
        stagger: 250,
        display: null,
        duration: 1000
    });
    var $more = $slide.find(".voda_more");
    $more.velocity("fadeIn", {
        duration: 1500,
        stagger: 150,
        delay: offset + 1500,
        display: null
    });
    return 1500 + $more.length * 1000;
}
function recalculateLines($force_slide, $lines) {
    var $slide = $currentSlide;
    if (typeof $force_slide != "undefined") {
        $slide = $force_slide;
    }
    if ($slide == null) {
        return;
    }
    var id = $slide.data("slide");
    var $b = $slide.find(".bottle_wrap");
    var $l = $lines;
    if (typeof $l == "undefined") {
        if(is_desktop()){
            $l = $(".gradient .lines[data-slide='" + id + "']");
        }else{
            $l = $(".slide_gfx .lines[data-slide='" + id + "']");

        }
    }
    if ($b.length == 0 || $l.length == 0) {
        return;
    }
    var offset = slides[id]['bottle']['offset'];
    if (typeof window.test_offset != "undefined") {
        offset = window.test_offset;
    }
    var ratio = $l[0].width / $l[0].naturalWidth;
    //$b.position().left
    //$b[0].offsetLeft
    var offsetLeft = 0;
    if(is_desktop()){
        offsetLeft = $b[0].offsetLeft + $b.parent()[0].offsetLeft
    }
    offsetLeft += - offset * ratio + $b.width() * 0.5;
    $l.css("left", offsetLeft+"px")
    //.css("top",$b[0].offsetTop + $b.parent()[0].offsetTop);
}

function _show_lines($slide, id, offset) {
    $slide.removeClass("flex");
    $slide[0].style.display = "none";
    $slide[0].offsetHeight;
    $slide.addClass("flex");
    recalculateMaxBottleHeight($slide);
    //console.log("fitext");
    if(is_mobile()){
        $slide.find(".slide_text .title").fitText(0.6);
    }
    render_static_bottle(id, $slide);
    setTimeout(function () {
        recalculateLines($slide);
        $(".gradient[data-slide='" + id + "']").find(".slide_gfx__fade").css("opacity", 1);
    }, offset);
}
