window.sessions = {};
window.img_sizes ={};
function _preload_bg(sid, obj) {
    return _do_preload(sid, obj, function (selector, sid, src) {
        $(selector).css("background-image", 'url(' + src + ')');
    });
}
function _preload_src(sid, obj) {
    return _do_preload(sid, obj, function (selector, sid, src, img) {
        //window.sessions[sid].$slide.find
        if(selector == 'lines' || selector == 'bottle'){
            if(selector == 'lines'){
                $(".gradient[data-slide='" +  window.sessions[sid].$slide.data("slide")+ "']").find(".slide_gfx__fade").attr("src",src);
            }else{
                //console.log("setting sid",sid,img);
                window.sessions[sid].$slide.find('.bottle').attr("src",src).data("real-width",img.width).data("real-height",img.height);
                _recalculate_animation_scale(window.sessions[sid].$slide);
                // window.sessions[sid].$slide.find('.bottle_wrap').css("background-image",'url('+src+')');
            }
        }else{
            $(selector).attr("src",src);// 'url(' + src + ')');
        }
    });
}
function _do_preload(sid, obj, callback) {
    var count = 0;
    for (var i in obj) {
        if (obj.hasOwnProperty(i) == false) {
            continue;
        }
        var img = new Image();
        img.src = obj[i];
        //console.log("SRC:",obj[i]);
        img.onload = (function (selector, sid, src, img) {
            return function(){
                //console.log(sid, src);
                ++window.sessions[sid].count;
                callback(selector,sid,src,img);
                _check_preload(sid);
                // window.img_sizes[src]=[img.width,img.height];
            }
        })(i, sid, obj[i], img);
        ++count;
    }
    return count;
}
function _check_preload(sid) {
    var s = sessions[sid];
    if (s.ended == false && s.enable_end && (s.count_added == 0 || s.count == s.count_added)) {
        s.ended = true;
        s.callback && s.callback();
        if(typeof s.queue != "undefined" && s.queue.length > 0){
            setTimeout((function(sid,session){
                for(var i=0;i<session.queue.length;++i){
                    var $slide = $(session.queue[i]);
                    preload(session.queue[i],$slide);
                }
            })(sid,s));
        }
    }
}
function is_preloaded(id) {
    return (typeof window.sessions[id] != "undefined") && window.sessions[id].ended;
}
function preload(id, $slide, callback_after) {

    if (typeof window.sessions[id] != "undefined") {
        return;
    }
    var p = assets[id];
    if(typeof p == "undefined"){
        p = {};
    }
    var sid = id;//Math.random();
    var count = 0;
    window.sessions[sid] = {
        ended: false,
        count: 0,
        enable_end: false,
        $slide: $slide,
        img: null,
        count_added: 0,
        queue: p.queue
    };
    if(typeof callback_after != "undefined"){
        window.sessions[sid].callback = callback_after;
    }
    if(is_mobile() && typeof p['mobile'] != "undefined"){
        count += _preload_bg(sid, p['mobile']['bg']);
        count += _preload_src(sid, p['mobile']['src']);
    }else{
        if(typeof p['bg'] != "undefined") {
            count += _preload_bg(sid, p['bg']);
        }
        if(typeof p['src'] != "undefined") {
            count += _preload_src(sid, p['src']);
        }
    }
    window.sessions[sid].enable_end = true;
    window.sessions[sid].count_added = count;
    _check_preload(sid);
    //_preload_bg(sid,$slide, p['bg']);
}